import Head from 'next/head';
import FundsLandingPage from '../src/containers/FundsLandingPage';

export default function Page() {
  const getPageTitle = () =>
    'Zorba | Grow and retain your client base with powerful real estate data.';
  const getPageDescription = () =>
    `Get the industry’s most accurate real estate data and the software you need to connect with, close, and retain your most valuable [investors, lenders, realtors, institutional investors] – all in one operating system.`;
  console.log('this is page index!')
  return (
    <>
      <Head>
        <title key="title">{getPageTitle()}</title>
        <meta key="og:title" property="og:title" content={getPageTitle()} />
        <meta
          key="twitter:title"
          property="twitter:title"
          content={getPageTitle()}
        />
        <meta
          key="description"
          name="description"
          content={getPageDescription()}
        />
        <meta
          key="og:description"
          property="og:description"
          content={getPageDescription()}
        />
      </Head>
      <FundsLandingPage pageName="fundsLandingPage" />
      </>
  );
};

// export default Home;
export async function getServerSideProps() {

  // Pass data to the page via props
  return { props: {  } }
}
